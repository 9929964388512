import React, { useState } from 'react'

export const ReadMoreToggleBlock = ({ title = "+ Meer lezen", onlyOnMobile = true, children, ...rest }) => {

	const [show, setShow] = useState(false)

	const classes = ( onlyOnMobile ? "sm:block " : "block ") + (show ? "block" : "hidden")
    const whenHidden = onlyOnMobile ? " sm:hidden " : " block "

	return (
		<div className="">
			<div className={"font-roboto-slab text-orange-400 font-semibold py-4 hover:underline cursor-pointer " + whenHidden} onClick={() => setShow(!show)}>{title}</div>
			<div className={classes}>
				{children}
			</div>
		</div>
	)
}