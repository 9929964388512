import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'
import PortableText from '../portableText'
import { ReadMoreToggleBlock } from '../blocks'

export const FaqSection = () => {

    return (
        <StaticQuery
            query={faqSectionQuery}
            render={({ site }) => {
                const { _rawFaq = [] } = site

                return (
                    <>
                        {_rawFaq.length > 0 &&
                            <section className="bg-white even:bg-gray-50 py-16">
                                <div className="max-w-6xl mx-auto lg:px-0 px-4">
                                    <h3 class="font-roboto-slab text-3xl text-gray-900">Enkele veelgestelde vragen</h3>
                                    <p class="mt-3 text-lg leading-6 leading-8 tracking-wide text-gray-600">Staat jouw vraag er niet tussen? Stel hem via ons contactformulier.</p>
                                    <div className="mt-8 grid gap-8" style={{ gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))" }}>
                                        {
                                            _rawFaq.map(({ question, answer }) => {
                                                return (
                                                    <div key={question} className="leading-8 text-base text-gray-500 ">
                                                        {/* {question && <h3 className="font-roboto-slab text-xl text-orange-400">{question}</h3>} */}
                                                        {answer && <div className="mt-4"><ReadMoreToggleBlock onlyOnMobile={false} title={"+ " + question}><PortableText blocks={answer} /></ReadMoreToggleBlock></div>}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </section>
                        }
                    </>
                )
            }}
        />
    )
}

const faqSectionQuery = graphql`
query faqSectionQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
	  _rawFaq
    }
}
`